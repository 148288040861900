<template>
  <ion-content class="ion-padding alert">
    <ion-row class="w-100">
      <ion-col>
        <table>
          <tr>
            <td width="10%" align="center">
              <ion-icon
                  :icon="warning"
                  style="color: #fff; font-size: 40px; margin-top: 10px"
              ></ion-icon>
<!--              <div style="padding: 10px">-->
<!--                <img src="../../../public/assets/icon/warning.svg">-->
<!--              </div>-->

            </td>
            <td>
              <div style="padding: 10px 0 5px 10px">
                <span class="alertTitle">Ir al médico</span>
              </div>

              <div style="padding: 0px 0px 10px 10px">
                <span class="alertContent">Desde GRAMoS, te insistimos que por tu condición debes ir al médico. </span>
              </div>
            </td>
            <td width="10%" valign="top">
              <div style="padding-top: 10px; cursor: pointer;">
                <img src="../../../public/assets/icon/closeModal.svg" v-on:click="cancel">
              </div>

            </td>
          </tr>
          <tr>
            <td width="25%" valign="top">
            </td>
            <td>
              <table>
                <tr>
                  <td>
                    <div style="padding: 0px 10px 0px 10px">
                      <span class="alertContent" style="letter-spacing: 1.5px; font-weight: bold; font-size: 14px;" v-on:click="confirm"> IGUAL QUIERO SUSCRIBIRME </span>
                    </div>
                  </td>
                  <td>
                    <div style="padding: 20px 10px 0px 10px">
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td width="10%">
            </td>
          </tr>
        </table>
      </ion-col>
    </ion-row>


  </ion-content>
</template>

<script>
import { IonContent, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import router from "../../router/index";
import { warning } from "ionicons/icons";

export default defineComponent({
  name: 'ModalRegistroSobrepeso',
  data() {
    return {
      warning
    }
  },
  components: { IonContent },
  methods: {
    confirm () {
      const modal = modalController;
      const data = true;
      return modal.dismiss(data);
    },
    cancel() {
      const modal =  modalController
      return modal.dismiss();
    },
  }
});
</script>

<style scoped>
.alert {
  --background: #d46827;
  text-align: left;
}

.alertTitle {
  color: white;
  font-family: "Avenir";
  font-size: 20px;
  letter-spacing: 1px;
}

.alertContent {
  color: white;
  font-family: "Avenir";
  font-size: 12px;
  letter-spacing: .75px;
  cursor: pointer;
}
</style>

